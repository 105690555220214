import {
  Center,
  Heading,
  Image,
  Text,
  useInterval,
  VStack,
} from "@chakra-ui/react"
import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { useEffectOnce } from "react-use"
import { usePrinticularApi } from "~/hooks/usePrinticularApi"
import { isMaintenance } from "~/services/ApiExceptionHelper"

const CHECK_DELAY = 90 * 1000 // 1'30"

interface Props {}

const Maintenance: React.FC<Props> = () => {
  const { t } = useTranslation()

  const defaultTitle = t("pages.maintenance.Title")
  const defaultDetails = t("pages.maintenance.Details")

  const [title, setTitle] = useState(defaultTitle)
  const [details, setDetails] = useState(defaultDetails)

  const api = usePrinticularApi()
  const checkServerStatus = useCallback(async () => {
    try {
      await api.ping()
      // no errors, redirect back to where we came from
      window.history.back()
    } catch (error: any) {
      if (isMaintenance(error)) {
        setTitle(previousTitle => error.errors[0]?.title || previousTitle)
        setDetails(
          previousDetails => error.errors[0]?.detail || previousDetails
        )
      }
    }
  }, [api])

  useEffectOnce(() => {
    checkServerStatus()
  })
  useInterval(checkServerStatus, CHECK_DELAY)

  return (
    <Center flexDirection="column" h="100vh">
      <Image
        src="images/page/maintenance.gif"
        maxHeight="50vh"
        mx="auto"
        mb={6}
      />
      <VStack spacing={3} maxWidth="md" px={6}>
        <Heading size="lg" textAlign="center">
          {title}
        </Heading>
        <Text
          fontSize={{ base: "md", sm: "xl" }}
          textAlign="center"
          lineHeight={1.3}
        >
          {details}
        </Text>
      </VStack>
    </Center>
  )
}

export default Maintenance
